.faq-container {
    width: 85%;
    z-index: 10;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    position: relative; /* Permite que o z-index funcione */

}

.titufaq{
    font-family: 'Space Grotesk', sans-serif;
    font-size: 96px;
    font-weight: 800;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    
}
  
.faq-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 15px 0;
}
  
.faq-question {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 25px;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 18px;
    color: #ffffff;
}
  
.faq-answer.open {
    max-height: 400px; /* Ajuste conforme o tamanho do conteúdo */
    padding-top: 10px;
}
  
.faq-arrow {
    transition: transform 0.3s ease;
}
  
.faq-arrow.open {
    transform: rotate(180deg);
}

.iconfaq{
    width: 97px;
    height: 97px;
}

.vr2{
    height: 70px;
    border: 1px solid rgba(255, 255, 255, 1);
    display: inline-block;
}

