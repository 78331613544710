.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0px 0;
}


.swiper {
    width: 100%;
    padding: 70px 0px 0px 0px;
}

.line{
  position: absolute;
  width: 100%;
  height: 120px;
  overflow: hidden;
  background: radial-gradient(
    ellipse at center,
    rgba(140, 1, 236, 0.8), /* Cor mais intensa no centro */
    rgba(140, 1, 236, 0.4) 55%, /* Gradual desvanecimento da cor */
    transparent 75% /* Borda transparente */
  );
  border-radius: 30px; /* Arredonda as bordas */
}


  
.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease, z-index 0.3s ease; /* Transição suave para todos os slides */
    z-index: 4;  /* Garantir que todos os slides tenham um z-index inicial */
}
.swiper-slide-prev .card {
  transform: translateY(-35px); /* Desce o slide anterior */
  z-index: 2; /* Garante que o slide anterior esteja acima do próximo, mas abaixo do ativo */
}

.swiper-slide-next .card {
  transform: translateY(-35px); /* Desce o slide próximo */
  z-index: 2; /* Garante que o slide próximo esteja acima do anterior, mas abaixo do ativo */
}

.swiper-slide-prev-prev, .swiper-slide-next-next {
  transform: translateY(40px); /* Desce ainda mais os slides mais distantes */
  z-index: 1; /* Garante que esses slides estejam abaixo dos outros */
}

.swiper-slide-active .card{
    transform: translateY(-70px); /* Eleva o slide ativo */
    z-index: 4; /* Garante que o slide ativo esteja acima de todos */
}
  
.card {
    background: #1c0759;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    width: 100%; /* Ajuste conforme necessário */
    height:  auto; /* Ajuste conforme necessário */
    display: flex;
    flex-direction: column;
    align-items: center;

    
}
  
.card img {
    width: 100%;
    height: auto;
    
}
  
.card-info {
    padding: 10px;
    text-align: center;
    width: 100%;
    color: white;
    backdrop-filter: blur(10px) ; /* Aplica o desfoque */ 
    background: rgba(0, 0, 0, 0.4) ;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset;
    opacity:  90%;

}
  
.card-info h3 {
    margin: 0;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 39.01px;
    text-align: center;

}


.description-container {
  margin-top: 0px;
  text-align: center;
  color: white;
   font-family: 'Space Grotesk', sans-serif;
  padding: 0 10%; /* Pode ajustar conforme necessário */
  font-size: 24px;
  font-weight: 600;
  line-height: 29.26px;
  text-align: justify;
  margin-bottom: 50px;
}
