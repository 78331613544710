.vertical-navbar {
    position: fixed;
    top: 45%;
    right: 3%; /* Altera de left para right para posicionar no lado direito */
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.25);
    padding: 10px;
    border-radius: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 40px; /* Largura da barra */
    box-shadow: -2px 2px 1.8px 1px rgba(255, 255, 255, 0.05) inset;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 3px 4px 30.1px 11px rgba(140, 1, 236, 0.16);

}

.nav-item {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-item img {
    width: 35px;
    height: 35px;
    filter: brightness(0) invert(1); /* Garante que os ícones fiquem brancos */
    transition: transform 0.3s ease, filter 0.3s ease;
    
}

.nav-item img:hover {
    transform: scale(1.1); /* Aumenta um pouco ao passar o mouse */
    filter: brightness(1) saturate(1.5) hue-rotate(-20deg); /* Muda para uma cor laranja ao passar o mouse */
}

.nav-item.active img {
    filter: brightness(1) saturate(2) hue-rotate(-5deg)  !important; /* Ícone ativo em laranja */
    transform: scale(1.2); /* Aumenta um pouco mais para destacar */
}

