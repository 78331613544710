body{
    background: rgba(28, 7, 89, 1);
    overflow-x: hidden;
}
html {
    scroll-behavior: smooth;
}


.infocenter{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.info-button{
    font-family: 'Space Grotesk', sans-serif;
    background: linear-gradient(92.7deg, rgba(0, 0, 0, 0.129) -1.08%, rgba(0, 0, 0, 0.2322) 90.55%);
    color: white;
    font-size: 22px;
    font-weight: 800;
    padding: 10px 5px;
    text-decoration: none;
    border-radius: 5px;
    border: 2px solid white;


}


.dregader{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;  
        
}

.videoabertura{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    z-index: 1;  
    object-fit: cover; 
    transform: translate(-46%, -60%);
    top: 45%;
    left: 50%;
    border-radius: 20px;
    opacity: 70%;
}

.container {
    position: relative;
    width: 100%;
    overflow: hidden; 
    aspect-ratio: 16/9; 
    
}

.cta-button {
    
    position: absolute;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 26px;
    font-weight: 800;
    text-align: center;
    top: 41%;  /* Ajuste conforme necessário para posicionar o botão */
    left: 53%;
    transform: translate(-50%, -50%);
    padding: 15px 20px;
    color: white; /* Cor do texto */
    text-transform: uppercase;
    border: 2px solid white;
    border-radius: 8px;
    z-index: 3;  /* Certifique-se de que o botão está acima da imagem e do vídeo */
    text-decoration: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Sombra opcional */
    background: linear-gradient(92.7deg, rgba(0, 0, 0, 0.129) -1.08%, rgba(0, 0, 0, 0.2322) 90.55%);
    backdrop-filter: blur(10px); /* Aplica o desfoque */    
    line-height: 39.01px;
    width: auto;
    height: auto;
    transition: transform 0.3s ease;

}
.cta-button:hover {
    transform: translate(-50%, calc(-50% - 10px)); /* Move o botão 10px para cima ao passar o mouse */
}

.rvlatos {
    position: absolute;
    top: 40%;  /* Centraliza verticalmente */
    left: 24%;  /* Centraliza horizontalmente */
    transform: translate(-50%, -50%);
    width: 25%;  /* Ajuste a largura conforme necessário */
    z-index: 3; 
}
.degradel {
    position: absolute;
    z-index: 0; /* Certifique-se de que o degradê está no fundo, atrás dos outros elementos */
    transform: rotate(296deg) scaleY(-1); /* Altere o valor para a rotação desejada */
    transform-origin: center; /* Garante que a rotação aconteça a partir do centro da imagem */
    right: 77%;
    width: 50%;
    opacity: 83%;
}
.info-button{
    display: none;
}

.barra{
    border: 1px solid rgba(255, 255, 255, 1);
    height: 54vh; /* Define a altura como 50% da altura da viewport no desktop */
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    flex-shrink: 0; /* Garante que a barra não encolha em layouts responsivos */

}

.text-container {
    flex: 1; /* O texto ocupa o espaço restante */
    color: white; /* Cor do texto */
    padding-right: 35px; /* Espaço entre o texto e a imagem */

}
.title-with-icon {
    display: flex;
    align-items: center; /* Alinha o ícone e o título verticalmente */
    gap: 10px; /* Espaço entre o ícone e o título */
    width: 110%;
}
.vr{
    height: 30px;
    border: 1px solid rgba(255, 255, 255, 1);
    display: inline-block;
}



.text-container h1 {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 40px;
    font-weight: 800;
    line-height: 54.86px;
    text-align: left;
    margin: 0;

}

.text-container p {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 29.26px;
    text-align: justify;

}

.image-info {
    flex: 1; /* A imagem ocupa o espaço restante */
    max-width: 50%; /* Limita a largura da imagem */
    height: auto; /* Mantém a proporção da imagem */
    border-radius: 10px; /* Adiciona bordas arredondadas à imagem */
}
.info-section {
    display: flex;
    justify-content: space-between; /* Espaço entre a barra/texto e a imagem */
    padding: 150px; /* Adicione padding interno para espaçamento */
    z-index: 3; /* Garante que a seção com a barra e o texto fique acima do degradê */
    position: relative;
    align-items: center; /* Alinha verticalmente */
    max-width: 100%;
    
    
}

.nave {
    width: 40%;
    height: auto;
    display: block;
    margin: 0 auto; /* Centraliza a imagem se necessário */
    border-radius: 10px; /* Bordas arredondadas, se desejado */

}

.linetitulo{
    font-family: 'Space Grotesk', sans-serif;
    font-size: 96px;
    font-weight: 800;
    line-height: 117.02px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    top: 20px;

}
.title-with-icon2 {
    display: flex;
    align-items: center; /* Alinha o ícone e o título verticalmente */
    gap: 10px; /* Espaço entre o ícone e o título */
    justify-content: center;
    height: 150px;
    padding-top: 50px;
}

.titlopass{
    font-family: 'Space Grotesk', sans-serif;;
    font-size: 96px;
    font-weight: 800;
    line-height: 117.02px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    z-index: 2;
}

.monu{
    position: absolute;
    align-items: center;
    z-index: 1;
    width: 35%;
    margin-top: 20px;
}

.revpass{
    display: flex;
    justify-content: center;
    
}

.folha1{
    position: absolute;
    justify-content: end;
    z-index: 1;
    width: 45%;
    display: flex;
    left: 0; /* Move a imagem para a esquerda */
    margin-top: 0px;
    margin-left: -70px;
}

.folha2{
    position: absolute;
    z-index: 1;
    width: 20%;
    display: flex;
    margin-top: 530px;
    transform: rotate(320deg) ; /* Altere o valor para a rotação desejada */
    transform-origin: center; /* Garante que a rotação aconteça a partir do centro da imagem */
    overflow: hidden; /* Garante que a parte que sai da tela seja cortada */
    left: 90%; /* Remove a configuração anterior */
    right: 0; /* Posiciona a imagem à direita */
}

.folha3{
    position: absolute;
    justify-content: end;
    z-index: 1;
    width: 25%;
    display: flex;
    margin-top: 420px;
    transform: rotate(296deg) scaleY(-1); /* Altere o valor para a rotação desejada */
    
}

.folha4{
    position: absolute;
    z-index: 1;
    width: 25%;
    display: flex;
    justify-content: end;
    margin-top: 750px;
    right: 93%;
    transform: rotate(286deg) scaleY(-1) ; /* Altere o valor para a rotação desejada */

}

.ingressosrev {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Cria uma grade com 2 colunas */
    grid-template-rows: repeat(2, 1fr); /* Cria 2 linhas */
    gap: 50px; /* Espaçamento entre os ingressos */
    z-index: 999; /* Certifica-se de que os ingressos estejam acima das plantas */
    text-align: center;
    margin: 50px;
    opacity: 0px;
    position: relative;

}
.ingressosrev a {
    width: 100%; /* O link ocupa 100% da célula da grid */
}
.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 colunas */
    grid-template-rows: repeat(2, 1fr); /* Cria 2 linhas */
    gap: 80px; /* Espaçamento entre os itens */
    width: 90%; /* Garante que o grid ocupe a largura total disponível */
    z-index: 999;
    position: relative;
    margin:0 auto;
    opacity: 100%;
    margin-bottom: 200px;
    height: auto;
    column-gap: 0px;


}


.g-col-6 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15%;
}


.g-col-6 img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.ing1, .ing2, .ing3, .ing4, .roomC, .caraC {
    width: 60%; /* Ajuste conforme necessário */
    height: auto;
    border-radius: 10px; /* Adiciona bordas arredondadas */
    z-index: 999;
    opacity: 80%;
    position: relative; /* Garante que o z-index funcione */

}


.iconinfo{
    width: 40px;
    height: 40px;
}

.iconline{
    width: 125px;
    height: 125px;

}

.iconpass{
    width: 131px;
    height: 131px;
    z-index: 2;
}
.sponsorship-icon{
    width: 125px;
    height: 125px;

}

.vr2{
    height: 70px;
    border: 1px solid rgba(255, 255, 255, 1);
    display: inline-block;
}
img {
    max-width: 100%;
    height: auto;
    display: block;
}

.flip-container {
    perspective: 1000px;
    width: 100%; /* Ocupe toda a largura disponível */
    height: 100%; /* Altura automática */

}

.flipper {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
    position: relative; /* Mantém o contexto relativo */
    text-align: center;

}
.flip-container.flipped .flipper {
    transform: rotateY(180deg);
  }
.flip-container:hover .flipper {
    transform: rotateY(180deg);
}

.front, .back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    border-radius: 10px;
}

.front {
    z-index: 2;
    transform: rotateY(0deg);
}

.back {
    transform: rotateY(180deg);
    z-index: 1;
}
.sponsorship-header{
    display: flex;
    align-items: center; /* Alinha o ícone e o título verticalmente */
    gap: 10px; /* Espaço entre o ícone e o título */
    justify-content: center;
    height: 150px;
    padding-top: 50px;
}
.sponsorship-logos {
    background-color: rgba(0, 0, 0, 1); /* Fundo preto atrás das imagens */
    display: flex;
    gap: 100px;
    justify-content: space-evenly;
    align-items: stretch;
    opacity: 90%;

}
  
.sponsorship-logos img {
    
    max-height: 100%; /* Ajuste conforme necessário */
    max-width: 19%; /* Ajuste conforme necessário */
    object-fit: contain;
    padding: 10px; /* Espaçamento interno para evitar que o fundo encoste na imagem */
}
.sponsorship-logos2{
    background-color: rgba(13, 13, 13, 1);
    display: flex;
    justify-content: center;
    align-items: stretch;
    opacity: 90%;
    height: 115px;
}

.sponsorship-logos2 img{
    max-height: 100%; /* Ajuste conforme necessário */
    max-width: 100%; /* Ajuste conforme necessário */
    object-fit: contain;
}

.titlopass2{
    font-family: 'Space Grotesk', sans-serif;
    font-size: 60px;
    font-weight: 800;
    line-height: 117.02px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    z-index: 2;
}

@media (min-width: 1051px) {
    .flip-container:hover .flipper {
      transform: rotateY(180deg);
    }
    
}
@media (max-width: 1050px){
    
    .mobile-button {
        display: block; /* Mostra o botão em telas pequenas */
        width: 100%; /* Faz o botão ocupar a largura total da tela */
    }
    .vertical-navbar {
        display: none !important; /* Esconde a navbar em telas menores */
    }
    .videoabertura{
        width: 85%;
        height: 70%;
        z-index: 2;
        opacity: 95%;
        transform: translate(-45%, -60%);
        left: 46%;
        margin-top: 20px;
        position: absolute;

    }
    .cta-button{
       display: none;
        
    }
    .dregader{
        width: 100%;
        z-index: 1;
    }
    .rvlatos{
        left: 10%;
        margin-top: 20px;
    }
    .info-section{
        padding: 10px;

    }
    .nave{
        display: none;
    }
    .degradel{
        width: 100%;
        right: 53%;
        top: 40%;
    }
    .text-container h1{
        font-size: 30px;
        line-height: 40px;
    }
    .text-container p{
        font-size: 15px;
        line-height: 20px;
    }
    .text-container{
        padding-right:10px;
    }
    .iconinfo{
        display: none;
    }
    
    .vr{
        display: none;
    }
    .vr2{
        display: none !important;
    }
    .barra{
        height: 82vh;
        margin-right: 10px;
    }
    .iconline{
        display: none;
    }
    .title-with-icon2{
        display: flex;
        justify-content: space-around;
        padding-top: 0px;
    } 
    .linetitulo{
        font-size: 80px;
        margin: 0px 0px;
    }
    .carousel-container {
        padding: 20px 0 ;
    }
    .swiper {
        padding: 0px 0 !important;
    }
    .card-info h3 {
        font-size: 15px !;
        line-height: 22px !important;
    }
    .swiper-slide {
        width: 100% !important;
    }
    .description-container {
        font-size: 16px !important;
    }
    .card{
        width: 100% !important;
    }
    .swiper-slide .card {
        width: 100% !important; /* Aumenta a largura dos cards no mobile */
        height:  auto;
    }
    .iconpass{
        display: none;
    }
    .titlopass{
        font-size: 35px;
    }
    .grid{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 100px;
    }
    .monu{
        width: 80%;
    }
    .folha1{
        width: 50%;
        transform: rotate(104deg) scaleY(-1); /* Altere o valor para a rotação desejada */
        margin-top: 220px;
    }
    .folha2{
        width: 50%;
        transform: rotate(346deg) ; /* Altere o valor para a rotação desejada */
        left: 40%;
        margin-top: 330px;
    }
    .folha3{
        width: 50%;
        margin-top: 550px;
    }
    .folha4{
        width: 50%;
        transform: rotate(271deg) scaleY(-1);
        right: 80%;
    }
    .iconfaq{
        display: none;

    }
    .titufaq{
        font-size: 70px !important;
        margin-bottom: 10px;
    }
    .faq-question{
        font-size: 20px !important;
    }
    .info-button{
        display: block;
        font-size: 20px;
    }
    .faq-answer.open {
        max-height: 800px !important; /* Ajuste conforme o tamanho do conteúdo */
        padding-top: 10px;
    }
    .title-with-icon{
        width: 100%;
    }
    .sponsorship-icon{
        display: none;
    }
    .sponsorship-logos{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .sponsorship-logos img {
        max-height: 50%; /* Ajuste conforme necessário */
        max-width: 50%; /* Ajuste conforme necessário */
        object-fit: contain;
    }
    .sponsorship-logos2 img{
        max-height: 85%; /* Ajuste conforme necessário */
        max-width: 90%; /* Ajuste conforme necessário */
        object-fit: contain;
    }
    .sponsorship-logos2{
        display: flex;
        justify-content: center;
        align-items: stretch;
        opacity: 90%;
        height: 90px;
    }
    
   
      
}

@media (min-width: 1050px) and (max-width: 1300px) {
    .barra {
        height: 155vh; /* Altura ajustada para telas maiores */
    }
    
}
@media (min-width: 1300px) and (max-width: 1900px) {
    .barra {
        height: 83vh; /* Altura ajustada para telas maiores */
    }
    .text-container {
        padding-right: 50px; /* Espaço entre o texto e a imagem */

    }

    
}
@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
      /* Estilos específicos para Safari */
      .videoabertura {
        object-fit: cover;
        /* outros ajustes */
      }
    }
}