.carousel-mobile-container {
    width: 100%;
    padding: 0px 0;
    overflow: hidden;
}
  
.mySwiperMobile {
    width: 100%;
}
  
.mobile-card {
    background: #1c0759;
    border-radius: 10px;
    overflow: hidden;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.mobile-card img {
    width: 100%;
    height: auto;
}
  
.mobile-card-info {
    padding: 10px;
    text-align: center;
    color: white;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    width: 100%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset;
    border-radius: 10px
}
  
.mobile-card-info h3 {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}
.description-container {
    margin-top: 0px;
    text-align: center;
    color: white;
    font-family: 'Space Grotesk', sans-serif;
    padding: 0 10%; /* Pode ajustar conforme necessário */
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    text-align: justify;
    margin-bottom: 50px;
}

.description-container p {
    margin: 25px 0px;
}
  